import { Modal } from './modal';
import { CSSProperties, useCallback, useEffect, useState } from 'react';
import cx from 'classnames';

import { Button } from '../button/button';
import { ASSETS, MINI_VOUCHER_IMAGES } from '../../utils/assetUtils';
import classes from './InstructionModal.module.css';
import { VoucherType } from '../../typing';
import {
  CAMPAIGN_END_DATE,
  CAMPAIGN_START_DATE,
  VoucherTypeMap,
  VOUCHER_VALUES,
} from '../../constants';
import { usePool } from '../../hooks';
import { formatNumber } from '../../utils/commonUtils';
import config from '../../config';
import gamefoxSDK from '../../gamefoxSDK';

type Props = {
  visible: boolean;
  onDismiss: () => void;
};

enum InstructionTab {
  RULE = 'RULE',
  GUIDE = 'GUIDE',
}

const exitButtonStyle: CSSProperties = {
  position: 'absolute',
  right: 0,
  top: `calc(var(--game-width) * -0.1)`,
};

const quests = [
  {
    icon: ASSETS.METHOD1_ICON,
    title: 'Mở tài khoản & đăng nhập lần đầu',
    des: 'Tối đa 6 lượt chơi',
  },
  {
    icon: ASSETS.METHOD2_ICON,
    title: 'Đăng nhập hàng ngày',
    des: 'Tối đa 1 lượt chơi/ ngày',
  },
];

const rewards = [
  {
    type: VoucherType.VOUCHER_3000K,
  },
  {
    type: VoucherType.VOUCHER_300K,
  },
  {
    type: VoucherType.VOUCHER_100K,
  },
  {
    type: VoucherType.VOUCHER_50K,
  },
  {
    type: VoucherType.VOUCHER_10K,
  },
];

export const InstructionModal: React.FC<Props> = ({ visible, onDismiss }) => {
  const [tab, setTab] = useState(InstructionTab.RULE);
  const [getPoolInterval, setGetPoolInterval] = useState<NodeJS.Timer>();

  const [pool, getPool] = usePool();
  const [displayPool, setDisplayPool] = useState<{ [key: string]: string }>();

  const onLinkClicked = useCallback((link: string) => {
    gamefoxSDK.postMessage({
      eventType: 'LINK_CLICKED',
      eventData: {
        link,
      },
    });
  }, []);

  useEffect(() => {
    if (visible) {
      setGetPoolInterval(interval => {
        interval && clearInterval(interval);
        return setInterval(() => {
          getPool();
        }, 5000);
      });
    }
  }, [getPool, visible]);

  useEffect(() => {
    if (!visible) {
      getPoolInterval && clearInterval(getPoolInterval);
      setGetPoolInterval(undefined);
    }
  }, [visible, getPoolInterval]);

  useEffect(() => {
    if (pool) {
      const displayPool: { [key: string]: string } = {};
      for (const item of pool) {
        if (typeof item.remaining !== 'number' || typeof item.workingTimeAmount !== 'number') {
          continue;
        }

        displayPool[VoucherTypeMap[item.itemType]] = `
        ${formatNumber(item.remaining)}/${formatNumber(item.workingTimeAmount)}`;
      }
      setDisplayPool(displayPool);
    }
  }, [pool]);

  const renderRule = useCallback(() => {
    return (
      <div className={classes['ruleContainer']}>
        <div className={cx(classes['ruleText1'], 'pro-regular')}>
          Mừng kỷ niệm 30 năm Ngân hàng Shinhan có mặt tại Việt Nam, từ ngày {CAMPAIGN_START_DATE}{' '}
          đến hết ngày {CAMPAIGN_END_DATE}, Ngân hàng Shinhan triển khai chương trình{' '}
          <span className="pro-bold">“Vào SOL quay số, nhận quà vô số”</span> dành cho khách hàng mở
          tài khoản thanh toán trực tuyến bằng phương thức định danh điện tử (eKYC), và khách hàng
          hiện hữu đăng nhập hàng ngày vào ứng dụng Shinhan SOL Việt Nam (“Ứng dụng SOL”).
        </div>
        <div className={cx(classes['ruleText2'], 'pro-regular')}>
          Theo đó, khi khách hàng thực hiện thành công mỗi bước trong quy trình mở tài khoản và thực
          hiện đăng nhập hàng ngày vào Ứng dụng SOL sẽ nhận được các lượt quay số để tham gia chương
          trình.
        </div>
        <div className={cx(classes['ruleText3'], 'pro-regular')}>
          Tổng giải thưởng trị giá <span className="pro-bold">300 triệu đồng.</span>
        </div>
        <div
          className={cx(classes['ruleText4'], 'pro-regular')}
          onClick={() => onLinkClicked(config.shareUrl)}
        >
          <span>Xem chi tiết </span>
          <span>[Thể lệ chương trình]</span>
        </div>

        <div className={classes['rewards']}>
          <div className={cx(classes['rewardTitle'], 'pro-regular')}>Cơ cấu giải thưởng</div>
          <div className={classes['rewardList']}>
            {rewards.map((reward, index) => {
              return (
                <div className={classes['rewardItem']} key={`reward${index}`}>
                  <div className={classes['rewardContent']}>
                    <div className={classes['rewardIcon']}>
                      <img src={MINI_VOUCHER_IMAGES[reward.type]} alt="" />
                    </div>
                    <div className={classes['rewardInfos']}>
                      <span className="pro-medium">
                        eVoucher Got It {VOUCHER_VALUES[reward.type]}
                      </span>
                      <span className="pro-regular">
                        Số quà còn lại hôm nay:{' '}
                        {displayPool?.[reward.type] ? displayPool[reward.type] : ''}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className={classes['ruleButtonRow']}>
          <Button title="backButton" onClick={onDismiss}>
            <div
              className={classes['ruleBackButton']}
              style={{
                backgroundImage: `url(${ASSETS.BACK_BUTTON})`,
              }}
            ></div>
          </Button>
        </div>
      </div>
    );
  }, [displayPool, onDismiss, onLinkClicked]);

  const renderGuide = useCallback(() => {
    return (
      <div className={classes['guideContainer']}>
        <div className={cx(classes['guideTitle'], 'pro-regular')}>Hướng dẫn cách chơi</div>
        <div className={cx(classes['guideDescription'], 'pro-regular')}>
          Quý khách hoàn thành nhiệm vụ theo quy định để lấy lượt chơi, sau đó bấm vào nút “QUAY
          NGAY” để quay vòng quay mắn và nhận thưởng.
        </div>
        <div className={classes['guideButtonWrapper']}>
          <div>
            <img className={classes['guideSpinButton']} src={ASSETS.SPIN_BUTTON} alt="" />
          </div>
        </div>
        <div className={cx(classes['guideTitle'], 'pro-regular')}>Hướng dẫn sử dụng quà</div>
        <div className={cx(classes['guideDescription'], 'pro-regular')}>
          eVoucher Got it được sử dụng để thanh toán tương đương tiền mặt tại cửa hàng, qua website,
          ứng dụng di động hoặc qua hotline/số điện thoại tại hơn 300 thương hiệu có liên kết với
          Got it.
        </div>
        <div className={cx(classes['guideDescription'], 'pro-regular')}>
          Mỗi eVoucher Got It được gửi kèm 1 link sử dụng riêng. Quý Khách hàng trúng thưởng vui
          lòng đăng nhập ứng dụng SOL và truy cập mục "Giỏ quà" để sử dụng.
        </div>
        <div className={cx(classes['guideDescription2'], 'pro-regular')}>
          <a
            href={config.voucherGotItGuideUrl}
            target={'_blank'}
            rel="noreferrer"
            onClick={() => onLinkClicked(config.voucherGotItGuideUrl)}
          >
            Chi tiết [Hướng dẫn sử dụng eVoucher Got it]
          </a>
        </div>
        <div className={cx(classes['guideTitle'], 'pro-regular')}>Điều kiện nhận lượt chơi</div>
        <div className={cx(classes['guideDescription'], 'pro-regular')}>
          Quý khách cần hoàn thành các nhiệm vụ dưới đây để nhận lượt chơi.
        </div>
        <div className={classes['guideQuestCardWrapper']}>
          {quests.map((quest, index) => {
            return (
              <div className={classes['guideQuestCard']} key={`quest_${index}`}>
                <div className={classes['guideIconWrapper']}>
                  <img
                    src={quest.icon}
                    alt=""
                    style={{
                      height: index === 0 ? '80%' : '99%',
                    }}
                  />
                </div>
                <div className={classes['guideContentWrapper']}>
                  <div className="pro-regular">{quest.title}</div>
                  <div className="pro-regular">{quest.des}</div>
                </div>
              </div>
            );
          })}
        </div>
        <div className={classes['guideButtonWrapper2']}>
          <Button title="backButton" onClick={onDismiss}>
            <div
              className={classes['ruleBackButton']}
              style={{
                backgroundImage: `url(${ASSETS.BACK_BUTTON})`,
              }}
            ></div>
          </Button>
        </div>
      </div>
    );
  }, [onDismiss, onLinkClicked]);

  return (
    <Modal visible={visible} onDismiss={onDismiss}>
      <div
        className={classes['container']}
        style={{
          backgroundImage: `url(${ASSETS.SHORT_MODAL_BD})`,
        }}
      >
        <Button title="exit" onClick={onDismiss} customStyle={exitButtonStyle}>
          <div
            className={classes['exitButton']}
            style={{
              backgroundImage: `url(${ASSETS.EXIT})`,
            }}
          ></div>
        </Button>
        <div className={classes['header']}>
          <img src={ASSETS.INSTRUCTION_HEADER_BANNER} alt="" />
        </div>
        <div className={classes['logo']}>
          <img src={ASSETS.LOGO} alt="" />
        </div>

        <div className={cx(classes['tabbar'], 'pro-medium')}>
          <div
            className={cx(
              classes['tabHighlight'],
              tab === InstructionTab.GUIDE && classes['tabTranslated']
            )}
          ></div>
          <div
            className={cx(
              classes['tab'],
              classes['tab1'],
              tab === InstructionTab.RULE && classes['tabFocused']
            )}
            onClick={() => {
              setTab(InstructionTab.RULE);
            }}
          >
            Thể lệ
          </div>
          <div
            className={cx(
              classes['tab'],
              classes['tab2'],
              tab === InstructionTab.GUIDE && classes['tabFocused']
            )}
            onClick={() => {
              setTab(InstructionTab.GUIDE);
            }}
          >
            Cách chơi
          </div>
        </div>
        <div className={classes['tabContent']}>
          {tab === InstructionTab.RULE ? renderRule() : renderGuide()}
        </div>
      </div>
    </Modal>
  );
};
