import { useCallback, useEffect, useState } from 'react';
import { Button } from '../../components/button/button';
import config from '../../config';
import gamefoxSDK from '../../gamefoxSDK';
import { ASSETS } from '../../utils/assetUtils';
import { AudioType, soundUtils } from '../../utils/soundUtils';
import {
  getMusicSetting,
  getSoundFXSetting,
  setMusicSetting,
  setSoundFXSetting,
} from '../../utils/storage';
import classes from './fixedTopContent.module.css';

type Props = {
  //
};

export const FixedTopContent: React.FC<Props> = () => {
  const [isSoundFxOn, setIsSoundFxOn] = useState(false);
  const [isMusicOn, setIsMusicOn] = useState(false);

  useEffect(() => {
    const isEnableMusic = getMusicSetting();
    setIsMusicOn(isEnableMusic);
    const isEnableSoundFx = getSoundFXSetting();
    setIsSoundFxOn(isEnableSoundFx);
  }, []);

  const onUpdateMusicSetting = useCallback(async (on: boolean) => {
    if (soundUtils.mute(AudioType.MUSIC, !on)) {
      soundUtils.setMusicEnable(on);
      setMusicSetting(on);
      setIsMusicOn(on);
    }
  }, []);

  const onUpdateSoundFXSetting = useCallback(async (on: boolean) => {
    if (soundUtils.mute(AudioType.SOUND_FX, !on)) {
      soundUtils.setSoundFxEnable(on);
      setSoundFXSetting(on);
      setIsSoundFxOn(on);
    }
  }, []);

  const onUpdateSound = useCallback(() => {
    const currentState = getMusicSetting();
    const newState = !currentState;
    onUpdateMusicSetting(newState);
    onUpdateSoundFXSetting(newState);
  }, [onUpdateMusicSetting, onUpdateSoundFXSetting]);

  const onShareClicked = useCallback(() => {
    gamefoxSDK.postMessage({
      eventType: 'SHARE',
      eventData: {
        link: config.shareUrl,
      },
    });
  }, []);

  return (
    <>
      <div className={classes['actionButtons']}>
        <Button title="share" onClick={onShareClicked}>
          <div
            className={classes['button']}
            style={{
              backgroundImage: `url(${ASSETS.SHARE_BUTTON})`,
            }}
          ></div>
        </Button>
        <Button
          title="sound"
          onClick={onUpdateSound}
          customStyle={{
            marginTop: `calc(var(--game-width) * 0.068)`,
          }}
        >
          <div
            className={classes['button']}
            style={{
              backgroundImage: `url(${isMusicOn ? ASSETS.UNMUTE_BUTTON : ASSETS.MUTE_BUTTON})`,
            }}
          ></div>
        </Button>
      </div>
    </>
  );
};
