export enum VoucherType {
  VOUCHER_0K = 'VOUCHER_0K',
  VOUCHER_10K = 'VOUCHER_10K',
  VOUCHER_50K = 'VOUCHER_50K',
  VOUCHER_100K = 'VOUCHER_100K',
  VOUCHER_300K = 'VOUCHER_300K',
  VOUCHER_3000K = 'VOUCHER_3000K',
}

export type VoucherItem = {
  _id: string;
  itemId: string;
  type: VoucherType;
  createdAt: string;
  expiredAt: string;
};
