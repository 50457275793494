import {
  AssetManager,
  createGameLoop,
  createViewport,
  PolygonBatch,
  TextureAtlas,
  TextureRegion,
  Vector2,
} from 'gdxts';
import {
  AnimationState,
  AnimationStateData,
  AtlasAttachmentLoader,
  Skeleton,
  SkeletonJson,
  SkeletonRenderer,
  SpineAssetManager,
} from 'gdxts-spine';
import { CANVAS_RATIO, EMITTER_EVENTS } from '../../constants';
import { VoucherType } from '../../typing';
import { INGAME_ASSETS } from '../../utils/assetUtils';
import { bezier } from '../../utils/customEasing';
import eventEmitter from '../../utils/eventEmitter';
import { lerp } from '../../utils/math';
import { MusicId, SoundFxId, soundUtils } from '../../utils/soundUtils';
import { getMusicSetting } from '../../utils/storage';
import { Timer } from '../../utils/Timer';

const SPINNING_TIME = 3;

enum ButtonState {
  ACTIVE = 'active',
  SLIDE = 'glow',
  DISABLE = 'disable',
}

export const initGame = async (canvas: HTMLCanvasElement) => {
  if (!canvas) return;

  const worldHeight = canvas.clientHeight || canvas.height;
  const worldWidth = worldHeight * CANVAS_RATIO;

  let numberOfTickets = 999;

  // TODO convert all to 414 621
  const viewport = createViewport(canvas, worldWidth, worldHeight, {
    autoUpdate: true,
    crop: false,
    pixelRatio: Math.min(2, window.devicePixelRatio),
    contextOption: {
      antialias: false,
    },
  });
  const camera = viewport.getCamera();
  camera.setYDown(true);
  const gl = viewport.getContext();
  const batch = new PolygonBatch(gl);
  batch.setYDown(true);
  const skeletonRenderer = new SkeletonRenderer(gl, true);

  const spineAssetManager = new SpineAssetManager(gl, '');
  spineAssetManager.loadTextureAtlas(INGAME_ASSETS.BUTTON_SPINE_ATLAS);
  spineAssetManager.loadJson(INGAME_ASSETS.BUTTON_SPINE_JSON);

  const assetManager = new AssetManager(gl);
  assetManager.loadAtlas(INGAME_ASSETS.ASSETS_ATLAS, 'atlas');
  // assetManager.loadFont(INGAME_ASSETS.FONT_ATLAS, 'font', true);
  await Promise.all([assetManager.finishLoading(), spineAssetManager.loadAll()]);

  const atlasLoader = new AtlasAttachmentLoader(
    spineAssetManager.get(INGAME_ASSETS.BUTTON_SPINE_ATLAS)
  );
  const skeletonJson = new SkeletonJson(atlasLoader);
  const skeletonData = skeletonJson.readSkeletonData(
    spineAssetManager.get(INGAME_ASSETS.BUTTON_SPINE_JSON)
  );
  const buttonSkeleton = new Skeleton(skeletonData);
  const buttonAnimationState = new AnimationState(new AnimationStateData(skeletonData));
  const trackEntry = buttonAnimationState.setAnimation(0, ButtonState.SLIDE, false);
  const slideDuration = trackEntry.animation?.duration || 0;

  const atlas = assetManager.getAtlas('atlas') as TextureAtlas;
  const outerTexture = atlas.findRegion('outer') as TextureRegion;
  const innerTexture = atlas.findRegion('inner') as TextureRegion;
  const arrowTexture = atlas.findRegion('arrow') as TextureRegion;
  const buttonTexture = atlas.findRegion('button') as TextureRegion;
  const centerTexture = atlas.findRegion('center') as TextureRegion;
  const decoTexture = atlas.findRegion('deco') as TextureRegion;
  const lightOnTexture = atlas.findRegion('light_on') as TextureRegion;

  // const font = assetManager.getFont('font') as BitmapFont;

  const outerHeight = worldHeight * 0.937;
  const outerWidth = (outerHeight * outerTexture.originalWidth) / outerTexture.originalHeight;
  const centerOffsetY = outerHeight * 0.379;
  const centerOffsetX = outerWidth * 0.5;
  const centerHeight = worldHeight * 0.1;
  const centerWidth = (centerHeight * centerTexture.originalWidth) / centerTexture.originalHeight;
  const innerHeight = outerHeight * 0.58;
  const innerWidth = (innerHeight * innerTexture.originalWidth) / innerTexture.originalHeight;
  const arrowHeight = worldHeight * 0.08;
  const arrowWidth = (arrowHeight * arrowTexture.originalWidth) / arrowTexture.originalHeight;
  const arrowOffsetY = outerHeight * 0.075;
  const decoHeight = outerHeight * 0.19;
  const decoWidth = (decoHeight * decoTexture.originalWidth) / decoTexture.originalHeight;
  const decoOffsetX = outerHeight * -0.01;
  const decoOffsetY = outerHeight * 0.65;
  const buttonHeight = worldHeight * 0.115;
  const buttonWidth = (buttonHeight * buttonTexture.originalWidth) / buttonTexture.originalHeight;
  const buttonX = worldWidth / 2 - buttonWidth / 2;
  const buttonY = outerHeight * 0.91;
  const lightOnHeight = worldHeight * 0.03;
  const lightOnWidth =
    (lightOnHeight * lightOnTexture.originalWidth) / lightOnTexture.originalHeight;

  const outerPosition: Vector2 = new Vector2(0, 0);
  const centerPosition: Vector2 = new Vector2(0, 0);
  const arrowPosition: Vector2 = new Vector2(0, 0);
  const decoPosition: Vector2 = new Vector2(0, 0);

  outerPosition.x = worldWidth / 2 - outerWidth / 2;
  outerPosition.y = worldHeight / 2 - worldHeight * 0.5;
  centerPosition.x = outerPosition.x + centerOffsetX;
  centerPosition.y = outerPosition.y + centerOffsetY;

  const spinTimer = new Timer();
  const spinDelayTimer = new Timer();
  const buttonSlideDelayTimer = new Timer();

  let isSpinning = false;
  const reward: { type: string } = {
    type: VoucherType.VOUCHER_0K,
  };

  const OUTER_SIZE = worldHeight * 0.55;
  const lights: { time: 0; on: boolean; pos: Vector2 }[] = [];
  const radius = OUTER_SIZE / 2;
  const offsets: number[][] = [
    [0, outerHeight * 0.05],
    [outerHeight * 0.018, outerHeight * 0.037],
    [outerHeight * 0.024, outerHeight * 0.029],
    [outerHeight * 0.026, outerHeight * 0.017],
    [outerHeight * 0.024, outerHeight * 0.008],
    [0, 0],
    [outerHeight * 0.004, outerHeight * -0.002],
    [outerHeight * -0.007, outerHeight * 0.001],
    [outerHeight * -0.013, outerHeight * 0.013],
    [outerHeight * -0.014, outerHeight * 0.023],
    [0, outerHeight * 0.03],
    [outerHeight * -0.003, outerHeight * 0.038],
  ];

  for (let i = 0; i < 12; i++) {
    const angle = (-(i * 360) / 12) * (Math.PI / 180) + 45;
    const offsetX = offsets?.[i]?.[0] || 0;
    const offsetY = offsets?.[i]?.[1] || 0;
    const x = radius * Math.cos(angle) + worldHeight * 0.328 + offsetX;
    const y = radius * Math.sin(angle) + OUTER_SIZE * 0.605 + offsetY;
    lights.push({
      pos: new Vector2(x, y),
      on: false,
      time: 0,
    });
  }

  const REWARD_MAPS: { [key in VoucherType]: number } = {
    [VoucherType.VOUCHER_3000K]: 6,
    [VoucherType.VOUCHER_0K]: 5,
    [VoucherType.VOUCHER_10K]: 4,
    [VoucherType.VOUCHER_300K]: 3,
    [VoucherType.VOUCHER_50K]: 2,
    [VoucherType.VOUCHER_100K]: 1,
  };

  const getTargetAngle = (type: VoucherType): number => {
    return ((2 * Math.PI) / 6) * REWARD_MAPS[type];
  };

  let touchingDown = false;
  let isFreeSpinning = false;
  let currentAnimation = ButtonState.SLIDE;
  let targetAngle = 0;
  let sourceAngle = 0;
  let angle = 0;

  const handleSpinTouchDown = () => {
    if (isSpinning) return;
    if (touchingDown) return;
    touchingDown = true;

    buttonAnimationState.setAnimation(0, ButtonState.ACTIVE, false);
    currentAnimation = ButtonState.ACTIVE;

    eventEmitter.emit(EMITTER_EVENTS.UPDATE_SPIN_BUTTON_ACTIVE_STATE, touchingDown);
  };

  const handleSpinTouchOutside = () => {
    if (!touchingDown) return;
    touchingDown = false;
    buttonAnimationState.setAnimation(0, ButtonState.SLIDE, true);
    currentAnimation = ButtonState.SLIDE;

    eventEmitter.emit(EMITTER_EVENTS.UPDATE_SPIN_BUTTON_ACTIVE_STATE, touchingDown);
  };

  const handleStartSpin = () => {
    if (!touchingDown) return;
    if (isSpinning) return;
    touchingDown = false;
    isSpinning = true;
    isFreeSpinning = true;

    eventEmitter.emit(EMITTER_EVENTS.UPDATE_SPIN_BUTTON_ACTIVE_STATE, touchingDown);
    eventEmitter.emit(EMITTER_EVENTS.UPDATE_SPIN_STATE, isSpinning);

    soundUtils.play(SoundFxId.ROLL);
    soundUtils.loadedSounds[MusicId.THEME]?.mute(true);
    soundUtils.stop(MusicId.THEME);
    soundUtils.play(MusicId.SPIN_THEME);

    buttonAnimationState.setAnimation(0, ButtonState.DISABLE, true);
    currentAnimation = ButtonState.DISABLE;
  };

  const handleSpinResult = (voucherType: VoucherType) => {
    if (!isSpinning) return;

    reward.type = voucherType;
    isFreeSpinning = false;

    // Calc target angle
    const centerAngle = getTargetAngle(voucherType) + Math.PI * 2 * 5;
    const minAngle = centerAngle - Math.PI / 6.4;
    const maxAngle = centerAngle + Math.PI / 6.4;
    const roundedAngle = Math.floor(angle / (Math.PI * 2)) * Math.PI * 2;
    targetAngle = roundedAngle + Math.random() * (maxAngle - minAngle) + minAngle;
    sourceAngle = angle;

    spinTimer.value = SPINNING_TIME * 1.05;
    spinTimer.onComplete = () => {
      soundUtils.stop(MusicId.SPIN_THEME);
      const isMusicOn = getMusicSetting();
      if (isMusicOn) {
        soundUtils.loadedSounds[MusicId.THEME]?.mute(false);
      }
      soundUtils.play(MusicId.THEME);

      isSpinning = false;
      buttonAnimationState.setAnimation(0, ButtonState.SLIDE, false);
      currentAnimation = ButtonState.SLIDE;

      eventEmitter.emit(EMITTER_EVENTS.UPDATE_SPIN_STATE, isSpinning);
      eventEmitter.emit(EMITTER_EVENTS.SHOW_REWARD, voucherType);
    };
  };

  const handleSpinError = () => {
    // Switch music
    soundUtils.stop(MusicId.SPIN_THEME);
    const isMusicOn = getMusicSetting();
    if (isMusicOn) {
      soundUtils.loadedSounds[MusicId.THEME]?.mute(false);
    }
    soundUtils.play(MusicId.THEME);

    // Stop spinning
    isSpinning = false;
    isFreeSpinning = false;

    eventEmitter.emit(EMITTER_EVENTS.UPDATE_SPIN_STATE, isSpinning);
  };

  const handleUpdateNumOfTickets = (amount: number) => {
    numberOfTickets = amount;
  };

  eventEmitter.addListener(EMITTER_EVENTS.SPIN_TOUCH_START, handleSpinTouchDown);
  eventEmitter.addListener(EMITTER_EVENTS.SPIN_TOUCH_OUTSIDE, handleSpinTouchOutside);
  eventEmitter.addListener(EMITTER_EVENTS.START_SPIN, handleStartSpin);
  eventEmitter.addListener(EMITTER_EVENTS.SPIN_RESULT, handleSpinResult);
  eventEmitter.addListener(EMITTER_EVENTS.SPIN_ERROR, handleSpinError);
  eventEmitter.addListener(EMITTER_EVENTS.UPDATE_NUMBER_OF_TICKETS, handleUpdateNumOfTickets);

  const processtimer = (delta: number) => {
    spinTimer.update(delta);
    spinDelayTimer.update(delta);
    buttonSlideDelayTimer.update(delta);
  };

  let spinnerIdleTimer = 0;
  let spinnerSpinTimer = 0;
  let spinnerSpinnerTimer = 0;

  const SWITCH_PATTERN_DELAY = 0.4;
  let currentPatternIndex = 0;
  const idlePatterns = ['even', 'odd', 'none'];

  let currentSpinLightIndex = 5;
  const SWITCH_LIGHT_DELAY = 0.06;

  let speed = 0;
  let speedAcc = worldHeight * 0.07;
  let maxSpeed = worldHeight * 0.03;

  const easingFunction = bezier(0.47, 0.87, 0.6, 1.04);

  const processSpinner = (delta: number) => {
    if (isSpinning) {
      spinnerIdleTimer = 0;
      spinnerSpinTimer += delta;
      if (spinnerSpinTimer > SWITCH_LIGHT_DELAY) {
        spinnerSpinTimer = 0;
        currentSpinLightIndex =
          currentSpinLightIndex === 0 ? lights.length - 1 : currentSpinLightIndex - 1;
      }

      if (!isFreeSpinning) {
        spinnerSpinnerTimer += delta;
        angle = lerp(
          sourceAngle,
          targetAngle,
          easingFunction(Math.min(1, spinnerSpinnerTimer / SPINNING_TIME))
        );
      } else {
        speed += speedAcc * delta;
        angle = Math.min(angle + maxSpeed * delta, angle + speed * delta);
      }
    } else {
      if (angle > Math.PI * 2) {
        angle = angle % (Math.PI * 2);
      }
      speed = 0;
      spinnerSpinTimer = 0;
      spinnerSpinnerTimer = 0;
      spinnerIdleTimer += delta;
      currentSpinLightIndex = 5;
      if (spinnerIdleTimer > SWITCH_PATTERN_DELAY) {
        spinnerIdleTimer = 0;
        currentPatternIndex =
          currentPatternIndex === idlePatterns.length - 1 ? 0 : currentPatternIndex + 1;
      }
    }

    if (!isSpinning) {
      const pattern = idlePatterns[currentPatternIndex];
      for (let i = 0; i < lights.length; i++) {
        const light = lights[i];
        if (pattern === 'even' && i % 2 === 0) {
          light.on = true;
        } else if (pattern === 'odd' && i % 2 === 1) {
          light.on = true;
        } else {
          light.on = false;
        }
      }
    }

    if (isSpinning) {
      for (let i = 0; i < lights.length; i++) {
        const light = lights[i];
        if (i === currentSpinLightIndex) {
          light.on = true;
        } else {
          light.on = false;
        }
      }
    }
  };

  let buttonSlideTime = 0;

  gl.clearColor(0, 0, 0, 1);
  const gameLoop = createGameLoop((delta: number) => {
    // gl.clear(gl.COLOR_BUFFER_BIT);

    processSpinner(delta);

    // Timer
    processtimer(delta);

    // Render
    batch.setProjection(camera.combined);
    batch.begin();
    // Outer
    outerTexture.draw(batch, outerPosition.x, outerPosition.y, outerWidth, outerHeight);

    // Inner
    innerTexture.draw(
      batch,
      centerPosition.x - innerWidth / 2 + outerHeight * 0.0015,
      centerPosition.y - innerHeight / 2 - outerHeight * 0.0015,
      innerWidth,
      innerHeight,
      innerWidth / 2,
      innerHeight / 2,
      angle
    );

    // Lights
    for (let i = 0; i < lights.length; i++) {
      const light = lights[i];
      if (!light.on) continue;
      lightOnTexture.draw(
        batch,
        light.pos.x - lightOnWidth / 2,
        light.pos.y - lightOnHeight / 2,
        lightOnWidth,
        lightOnHeight
      );
    }

    // Arrow
    arrowPosition.x = centerPosition.x;
    arrowPosition.y = outerPosition.y + arrowOffsetY;
    arrowTexture.draw(
      batch,
      arrowPosition.x - arrowWidth / 2,
      arrowPosition.y - arrowHeight / 2,
      arrowWidth,
      arrowHeight
    );

    // Deco
    decoPosition.x = centerPosition.x + decoOffsetX;
    decoPosition.y = outerPosition.y + decoOffsetY;
    decoTexture.draw(
      batch,
      decoPosition.x - decoWidth / 2,
      decoPosition.y - decoHeight / 2,
      decoWidth,
      decoHeight
    );

    // Center
    centerTexture.draw(
      batch,
      centerPosition.x - centerWidth / 2 + worldHeight * 0.001,
      centerPosition.y - centerHeight / 2 + worldHeight * 0.005,
      centerWidth,
      centerHeight
    );

    // Text
    // font.data.setXYScale((worldHeight * 0.03) / font.data.lineHeight);
    // batch.color.set(1, 1, 1, 1);
    // font.draw(
    //   batch,
    //   `Bạn còn ${numberOfTickets < 10 ? `0${numberOfTickets}` : numberOfTickets} lượt quay`,
    //   centerPosition.x - outerWidth * 0.49,
    //   centerPosition.y + outerHeight * 0.445,
    //   outerWidth,
    //   Align.center
    // );
    // font.data.setXYScale(1);

    if (slideDuration > 0) {
      if (currentAnimation === ButtonState.ACTIVE) {
        buttonSlideTime = 0;
        buttonSlideDelayTimer.value = 0;
      }

      if (buttonSlideDelayTimer.value <= 0) {
        buttonSlideTime += delta;
      }
      if (buttonSlideTime > slideDuration && buttonSlideDelayTimer.value <= 0) {
        buttonSlideDelayTimer.value = Math.random() * (5 - 3) + 3;
        buttonSlideTime = 0;
      }
    }

    let shouldUpdateButtonState = false;
    if (buttonSlideDelayTimer.value <= 0) {
      shouldUpdateButtonState = true;
    }

    buttonSkeleton.color.set(1, 1, 1, 1);

    if (
      (shouldUpdateButtonState && currentAnimation === ButtonState.SLIDE) ||
      currentAnimation === ButtonState.ACTIVE
    ) {
      buttonAnimationState.update(delta);
    }

    buttonAnimationState.apply(buttonSkeleton);
    buttonSkeleton.x = buttonX + buttonWidth * 0.44 + worldHeight * 0.01;
    buttonSkeleton.y = buttonY + buttonHeight * 1.2;
    const scale = worldHeight * 0.00028;
    buttonSkeleton.scaleX = scale;
    buttonSkeleton.scaleY = -scale;
    buttonSkeleton.updateWorldTransform();
    skeletonRenderer.draw(batch as any, buttonSkeleton);

    // Shadow
    // shadowTexture.draw(
    //   batch,
    //   outerPosition.x,
    //   outerPosition.y + outerHeight * 0.99,
    //   outerWidth,
    //   (outerWidth * shadowTexture.originalHeight) / shadowTexture.originalWidth
    // );
    batch.end();
  });

  return {
    dispose: () => {
      gameLoop.stop();
      batch.dispose();
      spineAssetManager.dispose();
      assetManager.disposeAll();
      eventEmitter.removeListener(
        EMITTER_EVENTS.UPDATE_NUMBER_OF_TICKETS,
        handleUpdateNumOfTickets
      );
      eventEmitter.removeListener(EMITTER_EVENTS.START_SPIN, handleStartSpin);
      eventEmitter.removeListener(EMITTER_EVENTS.SPIN_RESULT, handleSpinResult);
      eventEmitter.removeListener(EMITTER_EVENTS.SPIN_ERROR, handleSpinError);
    },
  };
};
