import { pointInRect } from 'gdxts';
import { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';
import config from '../../config';
import { CANVAS_RATIO, EMITTER_EVENTS, VoucherTypeMap } from '../../constants';
import { useInventory, useSpin } from '../../hooks';
import { VoucherType } from '../../typing';
import { ASSETS } from '../../utils/assetUtils';
import eventEmitter from '../../utils/eventEmitter';
import { randomInArray } from '../../utils/math';
import showNotice from '../../utils/showNotice';
import { initGame } from './gameManager';
import classes from './luckySpin.module.css';

type Props = {
  //
};

export const LuckySpin: React.FC<Props> = () => {
  const [canvasRef, setCanvasRef] = useState<HTMLCanvasElement | null>();
  const [gameManager, setGameManager] = useState<any>();

  const [inventory] = useInventory();

  const canvasRefCallback = (node: HTMLCanvasElement) => {
    if (node && !canvasRef) {
      setCanvasRef(node);
    }
  };

  useEffect(() => {
    if (canvasRef) {
      (async () => {
        const gameManager = await initGame(canvasRef);
        setGameManager(gameManager);
        eventEmitter.emit(EMITTER_EVENTS.GAME_INITED);
      })();
    }
  }, [canvasRef]);

  useEffect(() => {
    const handleGameInited = () => {
      eventEmitter.emit(EMITTER_EVENTS.UPDATE_NUMBER_OF_TICKETS, inventory?.tickets?.length || 0);
    };
    eventEmitter.addListener(EMITTER_EVENTS.GAME_INITED, handleGameInited);
    return () => {
      eventEmitter.removeListener(EMITTER_EVENTS.GAME_INITED, handleGameInited);
    };
  }, [inventory]);

  useEffect(() => {
    return () => {
      gameManager && gameManager.dispose();
    };
  }, [gameManager]);

  const renderCanvas = useMemo(() => {
    return (
      <div className={classes['canvasWrapper']}>
        <CanvasBackground />
        <canvas className={classes['canvas']} ref={canvasRefCallback}></canvas>;
        <CanvasOverlay />
      </div>
    );
  }, []);

  return <div>{renderCanvas}</div>;
};

const CanvasOverlay = () => {
  const [spinning, setSpinning] = useState(false);
  const [touchingDown, setTouchingDown] = useState(false);
  const [rerenderCounter, setRerenderCounter] = useState(0);
  const [width, setWidth] = useState(0);
  const [height, setheight] = useState(0);
  const [spin] = useSpin();
  const [inventory, getInventory] = useInventory();

  const buttonStyle = useMemo<CSSProperties>(() => {
    return {
      position: 'absolute',
      width: height * 0.43,
      height: (height * 0.43 * 80) / 268,
      left: width / 2 - height * 0.43 * 0.47,
      top: height * 0.86,
      // backgroundColor: `rgba(0,0,0,0.2)`,
    };
  }, [height, width]);
  const textStyle = useMemo<CSSProperties>(() => {
    return {
      position: 'absolute',
      width: height * 0.43,
      height: height * 0.08,
      left: width / 2 - height * 0.43 * 0.5,
      top: height * 0.743,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: `var(--fs-16)`,
      color: 'white',
      // backgroundColor: `rgba(0,0,0,0.2)`,
    };
  }, [height, width]);

  const refCallBack = useCallback(
    (node: HTMLDivElement) => {
      if (!node) return;
      const width = node.clientWidth;
      const height = node.clientHeight;
      let viewportWidth = 0;
      let viewportHeight = 0;
      if (width / height >= CANVAS_RATIO) {
        viewportHeight = height;
        viewportWidth = viewportHeight * CANVAS_RATIO;
      } else {
        viewportWidth = width;
        viewportHeight = viewportWidth / CANVAS_RATIO;
      }
      setWidth(viewportWidth);
      setheight(viewportHeight);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rerenderCounter]
  );

  useEffect(() => {
    const handleResize = () => {
      setRerenderCounter(counter => counter + 1);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const randomSpin = useCallback(() => {
    if (!touchingDown) return;
    if (spinning) return;

    eventEmitter.emit(EMITTER_EVENTS.START_SPIN);

    setTimeout(() => {
      const indices = [
        VoucherType.VOUCHER_0K,
        VoucherType.VOUCHER_10K,
        VoucherType.VOUCHER_50K,
        VoucherType.VOUCHER_100K,
        VoucherType.VOUCHER_300K,
        VoucherType.VOUCHER_3000K,
      ];
      const randomType = randomInArray(indices);
      // eventEmitter.emit(EMITTER_EVENTS.SPIN_ERROR);
      eventEmitter.emit(EMITTER_EVENTS.SPIN_RESULT, randomType);
    }, 300);
  }, [spinning, touchingDown]);

  const handleSpin = useCallback(async () => {
    if (!config.online) {
      randomSpin();
      return;
    }

    if (!touchingDown) return;
    if (spinning) return;
    if (!inventory) return;
    if (!inventory.tickets.length) {
      eventEmitter.emit(EMITTER_EVENTS.SHOW_OUT_OF_TICKET);
      return;
    }
    try {
      eventEmitter.emit(EMITTER_EVENTS.START_SPIN);
      const usingTicket = inventory.tickets[0];
      const reward = await spin(usingTicket.id);
      await getInventory();
      const rewardType = VoucherTypeMap[reward.itemType] || VoucherType.VOUCHER_0K;
      eventEmitter.emit(EMITTER_EVENTS.SPIN_RESULT, rewardType);
    } catch (error) {
      showNotice('Đã có lỗi xảy ra, vui lòng thử lại sau.');
      eventEmitter.emit(EMITTER_EVENTS.SPIN_ERROR);
    }
  }, [getInventory, inventory, spin, spinning, touchingDown, randomSpin]);

  useEffect(() => {
    const handleUpdateSpinButtonActiveState = (active: boolean) => {
      setTouchingDown(active);
    };
    const handleUpdateSpinState = (active: boolean) => {
      setSpinning(active);
    };

    eventEmitter.addListener(
      EMITTER_EVENTS.UPDATE_SPIN_BUTTON_ACTIVE_STATE,
      handleUpdateSpinButtonActiveState
    );
    eventEmitter.addListener(EMITTER_EVENTS.UPDATE_SPIN_STATE, handleUpdateSpinState);
    return () => {
      eventEmitter.removeListener(
        EMITTER_EVENTS.UPDATE_SPIN_BUTTON_ACTIVE_STATE,
        handleUpdateSpinButtonActiveState
      );
      eventEmitter.removeListener(EMITTER_EVENTS.UPDATE_SPIN_STATE, handleUpdateSpinState);
    };
  }, []);

  useEffect(() => {
    const isMobile = 'ontouchstart' in document.documentElement === true ? true : false;

    const handleTouchStart = (ev: any) => {
      const spinButton = document.getElementById('spinButton');
      if (spinButton) {
        const touchX = !isMobile ? ev.clientX : ev.changedTouches?.[0]?.clientX;
        const touchY = !isMobile ? ev.clientY : ev.changedTouches?.[0]?.clientY;
        const hitbox = spinButton.getBoundingClientRect();
        if (
          pointInRect(touchX, touchY, hitbox.x, hitbox.y, hitbox.width, hitbox.height) &&
          ev.target === spinButton
        ) {
          setTouchingDown(true);
          eventEmitter.emit(EMITTER_EVENTS.SPIN_TOUCH_START);
        }
      }
    };
    const handleTouchEnd = (ev: any) => {
      const spinButton = document.getElementById('spinButton');
      if (spinButton) {
        const touchX = !isMobile ? ev.clientX : ev.changedTouches?.[0]?.clientX;
        const touchY = !isMobile ? ev.clientY : ev.changedTouches?.[0]?.clientY;
        const hitbox = spinButton.getBoundingClientRect();

        if (
          pointInRect(touchX, touchY, hitbox.x, hitbox.y, hitbox.width, hitbox.height) &&
          ev.target === spinButton
        ) {
          handleSpin();
        } else {
          eventEmitter.emit(EMITTER_EVENTS.SPIN_TOUCH_OUTSIDE);
        }
        setTouchingDown(false);
      }
    };

    if (isMobile) {
      window.document?.addEventListener('touchstart', handleTouchStart);
      window.document?.addEventListener('touchend', handleTouchEnd);
    } else {
      window.document?.addEventListener('mousedown', handleTouchStart);
      window.document?.addEventListener('mouseup', handleTouchEnd);
    }

    return () => {
      if (isMobile) {
        window.document?.removeEventListener('touchstart', handleTouchStart);
        window.document?.removeEventListener('touchend', handleTouchEnd);
      } else {
        window.document?.removeEventListener('mousedown', handleTouchStart);
        window.document?.removeEventListener('mouseup', handleTouchEnd);
      }
    };
  }, [width, height, handleSpin]);

  return (
    <div className={classes['overlay']} ref={refCallBack}>
      <div
        className={classes['viewport']}
        style={{
          width,
          height,
        }}
      >
        <div className="pro-regular" style={textStyle}>
          Bạn còn&nbsp;
          <span
            style={{
              fontSize: `var(--fs-18)`,
            }}
          >
            {inventory?.tickets?.length || '00'}
          </span>
          &nbsp;lượt quay
        </div>
        <div style={buttonStyle} id="spinButton"></div>
      </div>
    </div>
  );
};

const CanvasBackground = () => {
  const [rerenderCounter, setRerenderCounter] = useState(0);
  const [width, setWidth] = useState(0);
  const [height, setheight] = useState(0);

  const refCallBack = useCallback(
    (node: HTMLDivElement) => {
      if (!node) return;
      const width = node.clientWidth;
      const height = node.clientHeight;
      let viewportWidth = 0;
      let viewportHeight = 0;
      if (width / height >= CANVAS_RATIO) {
        viewportHeight = height;
        viewportWidth = viewportHeight * CANVAS_RATIO;
      } else {
        viewportWidth = width;
        viewportHeight = viewportWidth / CANVAS_RATIO;
      }
      setWidth(viewportWidth);
      setheight(viewportHeight);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rerenderCounter]
  );

  useEffect(() => {
    const handleResize = () => {
      setRerenderCounter(counter => counter + 1);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={classes['background']} ref={refCallBack}>
      <div
        className={classes['viewport']}
        style={{
          width,
          height,
        }}
      >
        <div>
          <img
            className={classes['table']}
            style={{
              position: 'absolute',
              width: `calc(var(--game-width) * 1.1)`,
              height: `calc(var(--game-width) * 1.1 * 262 / 414)`,
              left: `calc(${width}px / 2 - var(--game-width) / 2)`,
              bottom: -height * 0.28,
            }}
            src={ASSETS.TABLE}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};
