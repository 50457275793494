import dayjs from 'dayjs';
import { memo, useEffect, useState } from 'react';

type Props = {
  startDate?: string;
};

const calculateTimeLeft = (difference: number) => {
  // const startTimeStamp = dayjs(startDate).unix() * 1000;
  // const difference = dayjs().endOf('day').unix() * 1000 - startTimeStamp;
  // const difference = endDate - timeUtil.getCurrentTime().unix() * 1000;

  const timeLeft: {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  } = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };

  if (difference > 0) {
    timeLeft.days = Math.floor(difference / (1000 * 60 * 60 * 24));
    timeLeft.hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    timeLeft.minutes = Math.floor((difference / 1000 / 60) % 60);
    timeLeft.seconds = Math.floor((difference / 1000) % 60);
  }

  return `${timeLeft.days > 0 ? timeLeft.days + ':' : ''}${
    timeLeft.hours < 10 ? '0' + timeLeft.hours : timeLeft.hours
  }:${timeLeft.minutes < 10 ? '0' + timeLeft.minutes : timeLeft.minutes}:${
    timeLeft.seconds < 10 ? '0' + timeLeft.seconds : timeLeft.seconds
  }`;
};

let interval: NodeJS.Timeout;

const EventRawTimerFC: React.FC<Props> = ({ startDate }) => {
  const [timeLeft, setTimeLeft] = useState('');
  const [, setTotalTime] = useState(0);

  useEffect(() => {
    if (typeof startDate === 'string') {
      const totalTime = dayjs().endOf('day').unix() * 1000 - dayjs(startDate).unix() * 1000;
      setTotalTime(totalTime);
      setTimeLeft(calculateTimeLeft(totalTime));
      interval = setInterval(() => {
        setTotalTime(time => {
          const newTotal = Math.max(0, time - 1000);
          setTimeLeft(calculateTimeLeft(newTotal));
          return newTotal;
        });
      }, 1000);
    }

    return () => {
      interval && clearInterval(interval);
    };
  }, [startDate]);

  return <span>{timeLeft}</span>;
};

export const EventRawTimer = memo(EventRawTimerFC);
