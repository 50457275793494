import { useEffect, useState } from 'react';
import { ASSETS } from '../../utils/assetUtils';
import { soundUtils } from '../../utils/soundUtils';
import classes from './loadingBar.module.css';

const assets = {
  images: Object.values(ASSETS),
  sounds: ['all'],
};

let totalFile = assets.images.length + assets.sounds.length;
const delta = 1 / totalFile;

type Props = {
  onComplete: () => void;
};

const loadImage = (src: string) => {
  return new Promise(resolve => {
    const image = new Image();
    image.onload = () => {
      resolve(image);
    };
    image.src = src;
  });
};

export const LoadingBar: React.FC<Props> = ({ onComplete }) => {
  let [progress, setProgress] = useState(0);

  useEffect(() => {
    if (progress >= 0.98) {
      onComplete();
    }
  }, [onComplete, progress]);

  useEffect(() => {
    const load = async () => {
      for (const _ of assets.sounds) {
        await soundUtils.initSounds('/assets/sounds/');
        setProgress(p => p + delta);
      }

      for (const image of assets.images) {
        loadImage(image).then(() => {
          setProgress(p => p + delta);
        });
      }
    };

    (async () => {
      await load().catch(console.log);
    })();
  }, []);

  return (
    <div className={classes['container']}>
      <div
        className={classes['progress']}
        style={{
          width: '30%',
        }}
      ></div>
    </div>
  );
};
