import dayjs from 'dayjs';
import { Inventory, ItemInfo, Pool, SystemInfo, UserQuest } from './gamefoxSDK';

export const dummyBasketItems = [
  {
    id: '',
    itemId: '',
    createdAt: dayjs('04/14/2023', 'MM/DD/YYYY').toString(),
    type: 'EV_3M',
  },
  {
    id: '',
    itemId: '',
    createdAt: dayjs('04/14/2023', 'MM/DD/YYYY').toString(),
    type: 'EV_10K',
  },
  {
    id: '',
    itemId: '',
    createdAt: dayjs('04/14/2023', 'MM/DD/YYYY').toString(),
    type: 'EV_50K',
  },
  {
    id: '',
    itemId: '',
    createdAt: dayjs('04/14/2023', 'MM/DD/YYYY').toString(),
    type: 'EV_100K',
  },
  {
    id: '',
    itemId: '',
    createdAt: dayjs('04/14/2023', 'MM/DD/YYYY').toString(),
    type: 'EV_300K',
  },
];

export const dummyEkycQuests: UserQuest[] = [
  {
    claimed: true,
    claimedAt: '',
    description: 'Bước 1. Cài đặt',
    goal: 1,
    name: 'eKYC',
    progress: 1,
    progressAt: '',
    questId: '',
    userQuestId: '',
  },
  {
    claimed: true,
    claimedAt: '',
    description: 'Bước 2. Xác thực thiết bị',
    goal: 1,
    name: 'eKYC',
    progress: 1,
    progressAt: '',
    questId: '',
    userQuestId: '',
  },
  {
    claimed: true,
    claimedAt: '',
    description: 'Bước 3. Xác minh giấy tờ',
    goal: 1,
    name: 'eKYC',
    progress: 1,
    progressAt: '',
    questId: '',
    userQuestId: '',
  },
  {
    claimed: true,
    claimedAt: '',
    description: 'Bước 4. Xác thực khuôn mặt',
    goal: 1,
    name: 'eKYC',
    progress: 1,
    progressAt: '',
    questId: '',
    userQuestId: '',
  },
  {
    claimed: true,
    claimedAt: '',
    description: 'Bước 5. Xác nhận đăng ký tài khoản',
    goal: 1,
    name: 'eKYC',
    progress: 0,
    progressAt: '',
    questId: '',
    userQuestId: '',
  },
  {
    claimed: true,
    claimedAt: '',
    description: 'Bước 6. Đăng nhập Shinhan SOL ',
    goal: 1,
    name: 'eKYC',
    progress: 0,
    progressAt: '',
    questId: '',
    userQuestId: '',
  },
];

export const dummyInventory: Inventory = {
  id: '',
  items: dummyBasketItems,
  resources: {},
  tickets: [],
  type: '',
};

export const dummyItems: ItemInfo[] = [];

export const dummySystemInfos: SystemInfo = {
  currentTime: dayjs().toString(),
};

export const dummyPool: Pool = [
  {
    createdAt: '2023-05-16T10:23:04.015Z',
    endTime: '2023-06-16T10:23:03.712Z',
    itemType: 'EV_100K',
    itemId: '6463598834d7b5ca16e448fb',
    name: 'E-Voucher 100.000 VNĐ',
    startTime: '2023-05-16T10:23:03.712Z',
    status: 'ACTIVE',
    unclaimed: 600,
    weight: 2,
    remaining: 10,
    workingTimeAmount: 20,
  },
  {
    createdAt: '2023-05-16T10:23:04.015Z',
    endTime: '2023-06-16T10:23:03.712Z',
    itemType: 'EV_50K',
    itemId: '6463598834d7b5ca16e448fb',
    name: 'E-Voucher 50.000 VNĐ',
    startTime: '2023-05-16T10:23:03.712Z',
    status: 'ACTIVE',
    unclaimed: 1500,
    weight: 2,
    remaining: 10,
    workingTimeAmount: 20,
  },
  {
    createdAt: '2023-05-16T10:23:04.015Z',
    endTime: '2023-06-16T10:23:03.712Z',
    itemType: 'EV_3M',
    itemId: '6463598834d7b5ca16e448fb',
    name: 'E-Voucher 3.000.000 VNĐ',
    startTime: '2023-05-16T10:23:03.712Z',
    status: 'ACTIVE',
    unclaimed: 30,
    weight: 2,
    remaining: 1,
    workingTimeAmount: 1,
  },
  {
    createdAt: '2023-05-16T10:23:04.015Z',
    endTime: '2023-06-16T10:23:03.712Z',
    itemType: 'EV_10K',
    itemId: '6463598834d7b5ca16e448fb',
    name: 'E-Voucher 10.000 VNĐ',
    startTime: '2023-05-16T10:23:03.712Z',
    status: 'ACTIVE',
    unclaimed: 3000,
    weight: 2,
    remaining: 10,
    workingTimeAmount: 100,
  },
  {
    createdAt: '2023-05-16T10:23:04.015Z',
    endTime: '2023-06-16T10:23:03.712Z',
    itemType: 'EV_300K',
    itemId: '6463598834d7b5ca16e448fb',
    name: 'E-Voucher 300.000 VNĐ',
    startTime: '2023-05-16T10:23:03.712Z',
    status: 'ACTIVE',
    unclaimed: 150,
    weight: 2,
    remaining: 10,
    workingTimeAmount: 19,
  },
];
