const config = {
  online: true,
  // prod
  apiUrl: 'https://shinhan.gamefox.vn',
  // // test
  // apiUrl: 'https://shinhan-dev.gamefox.vn',
  shareUrl: 'https://shinhan.com.vn/vi/promotion/vao-sol-quay-so-nhan-qua-vo-so.html',
  ruleGuideUrl:
    'https://docs.google.com/viewerng/viewer?url=https://shinhan.com.vn/public/uploads/personal/Digital%20banking/Game/Gamification%20Jun2023%20-%20TnC%20-%20VI%20-%20Final.pdf',
  voucherGotItGuideUrl:
    'https://docs.google.com/viewerng/viewer?url=https://shinhan.com.vn/public/uploads/Promotion/2023/DMD/Jun%20Campaign%20-%20Gamification/User%20Manual_eVoucher%20Got%20It.pdf',
};

export default config;
