import cx from 'classnames';
import { useCallback, useMemo } from 'react';
import { Button } from '../../components/button/button';
import { EventRawTimer } from '../../components/timer/eventRawTimer';
import { CAMPAIGN_END_DATE, CAMPAIGN_START_DATE } from '../../constants';
import gamefoxSDK from '../../gamefoxSDK';
import { useQuest, useSystemInfo } from '../../hooks';
import { ASSETS } from '../../utils/assetUtils';

import classes from './questSection.module.css';

type Props = {
  //
};

export const QuestSection: React.FC<Props> = () => {
  const [systemInfos] = useSystemInfo();
  const [quests] = useQuest();

  const ekycQuests = useMemo(() => {
    const qs = quests.filter(quest => quest.name.includes('eKYC'));
    return qs;
  }, [quests]);

  const doQuest = useCallback(() => {
    const nextQuestIdx = ekycQuests.findIndex(quest => quest.progress < quest.goal);
    if (nextQuestIdx > -1) {
      gamefoxSDK.postMessage({
        eventType: 'DO_QUEST',
        eventData: {
          ...ekycQuests[nextQuestIdx],
          key: ekycQuests[nextQuestIdx].name,
        },
      });
    }
  }, [ekycQuests]);
  const doLoginQuest = useCallback(() => {
    const loginQuest = quests.find(quest => quest.name.includes('Đăng nhập'));
    if (loginQuest) {
      gamefoxSDK.postMessage({
        eventType: 'DO_QUEST',
        eventData: {
          ...loginQuest,
          key: 'daily-login',
        },
      });
    }
  }, [quests]);

  const hasCompletedEkyc = useMemo(
    () => ekycQuests.every(quest => quest.progress >= quest.goal),
    [ekycQuests]
  );
  const hasLogin = useMemo(() => {
    const loginQuest = quests.find(quest => !quest.name.includes('eKYC'));
    if (!loginQuest) {
      return false;
    }
    return loginQuest.progress >= loginQuest.goal;
  }, [quests]);

  return (
    <div className={classes['container']} id="MISSION_DETAIL">
      <div className={classes['wrapper']}>
        <div className={cx(classes['title'], 'pro-regular')}>Tìm lượt quay</div>
        <div className={classes['section']}>
          <div className={classes['sectionHeader']}>
            <div className={classes['headerIconWrapper']}>
              <img src={ASSETS.METHOD1_ICON} alt="" />
            </div>
            <div className={classes['headerInfoWrapper']}>
              <div className={cx(classes['headerTitle'], 'pro-regular')}>Mở tài khoản qua eKYC</div>
              <div className={cx(classes['headerDes'], 'pro-regular')}>
                01 lượt quay/bước (tối đa 06 lượt quay)
              </div>
            </div>
          </div>
          <div className={cx(classes['sectionDes'], 'pro-regular')}>
            Người chơi sẽ nhận tối đa 06 lượt quay tương ứng với 06 bước trong quy trình mở tài
            khoản bằng phương thức định danh điện tử (eKYC) trên Ứng dụng Shinhan SOL Việt Nam ("Ứng
            dụng SOL") trong thời gian quy định (Từ ngày {CAMPAIGN_START_DATE} đến ngày{' '}
            {CAMPAIGN_END_DATE}).
          </div>
          <div className={classes['stepsWrapper']}>
            {ekycQuests.map((step, index) => {
              const isComplete = index < 3 || step.progress >= step.goal;

              return (
                <div className={classes['stepItem']} key={`step_${index}`}>
                  <div
                    className={classes['stepStatusIcon']}
                    style={{
                      backgroundColor: isComplete ? '#FCA425' : '#BEC1C6',
                    }}
                  >
                    {isComplete ? (
                      <img src={ASSETS.CHECK_ICON} alt="" />
                    ) : (
                      <span className="sh-regular">+{step.goal}</span>
                    )}
                  </div>
                  <div
                    className={cx(classes['stepDes'], 'pro-regular')}
                    style={{
                      color: isComplete ? '#FCA425' : '#74767d',
                    }}
                  >
                    {step.description}
                  </div>
                  {index !== ekycQuests.length - 1 && (
                    <div className={classes['stepConnector']}></div>
                  )}
                </div>
              );
            })}
          </div>
          <div className={classes['warning']}>
            <img src={ASSETS.WARNING_ICON} alt="" />
            <div className="pro-regular">
              Quý khách phải hoàn thành ít nhất 03 bước đầu tiên để thỏa điều kiện tham gia quay số
            </div>
          </div>
          <Button title="action" onClick={doQuest} disabled={hasCompletedEkyc}>
            <div
              className={cx(classes['actionButton'], 'pro-regular')}
              style={{
                backgroundImage: `url(${
                  hasCompletedEkyc ? ASSETS.DEACTIVE_BUTTON : ASSETS.YELLOW_BUTTON
                })`,
              }}
            >
              {hasCompletedEkyc ? 'Đã hoàn thành' : 'Thực hiện ngay'}
            </div>
          </Button>
        </div>
        <div className={classes['dailySection']} id="MISSION_LOGIN">
          <div className={classes['top']}>
            <div className={classes['sectionHeader']}>
              <div className={classes['headerIconWrapper']}>
                <img src={ASSETS.METHOD2_ICON} alt="" />
              </div>
              <div className={classes['headerInfoWrapper']}>
                <div className={cx(classes['headerTitle'], 'pro-regular')}>Đăng nhập hằng ngày</div>
                <div className={cx(classes['headerDes'], 'pro-regular')}>
                  Tối đa 01 lượt quay/ngày
                </div>
              </div>
            </div>
            <div className={cx(classes['sectionDes2'], 'pro-regular')}>
              Người chơi thực hiện đăng nhập vào Ứng dụng SOL hàng ngày để nhận thêm lượt chơi, nhằm
              tăng vận may, quay vào ô giải thưởng giá trị lớn.
            </div>
            <div className={cx(classes['sectionDes3'], 'pro-regular')}>
              * Riêng đối với khách hàng mới mở thành công tài khoản qua eKYC trong thời gian khuyến
              mại (giả sử vào ngày T), lượt quay cộng thêm cho việc đăng nhập hàng ngày sẽ bắt đầu
              tính từ ngày T+1.
            </div>
          </div>
          <div className={classes['bot']}>
            {!hasLogin && <img src={ASSETS.BOOKMARK} alt="" className={classes['bookmark']} />}
            <div className={cx(classes['status'], 'pro-semibold')}>
              {hasLogin ? 'Đã hoàn thành' : 'Bạn chưa đăng nhập Ứng dụng SOL'}
            </div>
            {hasLogin ? (
              <div className={cx(classes['timeLeft'], 'pro-regular')}>
                Bạn còn&nbsp;
                <span className={classes['timer']}>
                  {systemInfos && <EventRawTimer startDate={systemInfos.currentTime} />}
                </span>
                &nbsp;để tiếp tục nhiệm vụ
              </div>
            ) : (
              <button className="pro-regular" onClick={doLoginQuest}>
                Đăng nhập ngay để thêm lượt quay nhé!
              </button>
            )}
          </div>
          {/* <Button
            disabled={true}
            title="action"
            customStyle={{
              marginTop: `calc(var(--game-width) * 0.05)`,
            }}
          >
            <div
              className={classes['actionButton2']}
              style={{
                backgroundImage: `url(${ASSETS.COMPLETE_BUTTON})`,
              }}
            >
              <span className="pro-regular">Đã hoàn thành</span>
              <span className="pro-regular">
                {systemInfos && <EventRawTimer startDate={systemInfos.currentTime} />}
              </span>
            </div>
          </Button> */}
        </div>
        <div className={classes['deco']}>
          <img src={ASSETS.BOTTOM_DECO} alt="" />
        </div>
      </div>
    </div>
  );
};
